import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';  // Import concat and Signature from ethers v6
// import { ZeroAddress, concat } from 'ethers';
import './ContractInteraction.css';
import userAccountABI from './UserAccountABI.json';


function ContractInteraction() {
  const { contractAddress } = useParams();
  const [contract, setContract] = useState(null);
  const [profile, setProfile] = useState({});
  const [totalAcces, setTotalAcces] = useState(0);
  const [totalBlacxes, setTotalBlacxes] = useState(0);
  const [gender, setGender] = useState(null);
  const [accesBalance, setAccesBalance] = useState(0);
  const [blacxesBalance, setBlacxesBalance] = useState(0);
  const [oliBalance, setOliBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [owners, setOwners] = useState([]);
  const [threshold, setThreshold] = useState(0);

  const blacxesAddress = process.env.REACT_APP_BLACXES_ADDRESS;
  const oliAddress = process.env.REACT_APP_OLI_ADDRESS;

  // Use refs for provider and signer to make them accessible in all functions
  const providerRef = useRef(null);
  const signerRef = useRef(null);

  useEffect(() => {
    async function loadContract() {
      if (!window.ethereum) return;

      setLoading(true);

      // Initialize provider and signer if they are not already set
      if (!providerRef.current) {
        providerRef.current = new ethers.BrowserProvider(window.ethereum);
      }
      if (!signerRef.current) {
        signerRef.current = await providerRef.current.getSigner();
      }

      const contractInstance = new ethers.Contract(contractAddress, userAccountABI, signerRef.current);
      setContract(contractInstance);

      const userName = await contractInstance.userName();
      const totalAcces = await contractInstance.totalAcces();
      const totalBlacxes = await contractInstance.totalBlacxes();
      const gender = await contractInstance.gender(contractAddress);

      setProfile({ userName });
      setTotalAcces(ethers.formatUnits(totalAcces, 18));
      setTotalBlacxes(totalBlacxes.toString());
      setGender(gender === 1n ? "Male" : gender === 2n ? "Female" : "Not Set");

      const accesBalance = await providerRef.current.getBalance(contractAddress);

      const blacxesContract = new ethers.Contract(blacxesAddress, ["function balanceOf(address, uint256) view returns (uint256)"], providerRef.current);
      const oliContract = new ethers.Contract(oliAddress, ["function balanceOf(address) view returns (uint256)"], providerRef.current);

      const blacxesBalance = await blacxesContract.balanceOf(contractAddress, 0);
      const oliBalance = await oliContract.balanceOf(contractAddress);

      setAccesBalance(ethers.formatUnits(accesBalance, 18));
      setBlacxesBalance(blacxesBalance.toString());
      setOliBalance(oliBalance.toString());

      const currentOwners = await contractInstance.getOwners();
      const currentThreshold = await contractInstance.getThreshold();

      setOwners(currentOwners);
      setThreshold(currentThreshold.toString());

      setLoading(false);
    }

    loadContract();
  }, [contractAddress, blacxesAddress, oliAddress]);

  // Function to handle transaction creation, signing, and execution using ethers v6
  const switchAccount = async () => {
    try {
      console.log("Requesting to switch accounts...");
      await window.ethereum.request({
        method: 'wallet_requestPermissions',
        params: [{ eth_accounts: {} }],
      });

      // Re-request accounts access and update the signer
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Update the signer to the newly selected account
      providerRef.current = new ethers.BrowserProvider(window.ethereum);
      signerRef.current = await providerRef.current.getSigner();

      console.log("Switched account successfully.");
    } catch (error) {
      console.error("Error switching account:", error);
    }
  };

  const handleAddOwner = async (newOwnerAddress, newThreshold) => {
    if (!contract) return;
    
    // Prepare the transaction data to add an owner
    const addOwnerWithThresholdData = contract.interface.encodeFunctionData('addOwnerWithThreshold', [newOwnerAddress, newThreshold]);
    console.log("Encoded Data: " + addOwnerWithThresholdData);
    // Call the function to propose the transaction
    await proposeSafeTransaction(addOwnerWithThresholdData);
  };
  // Function to propose a Safe transaction
  const proposeSafeTransaction = async (functionData) => {
    try {
      // Step 1: Prepare the transaction object
      const transaction = {
        to: contractAddress,
        value: 0, // No ETH being sent
        data: functionData,
        operation: 0, // CALL operation
        safeTxGas: 0, // Set to 0 to let the Safe estimate gas
        baseGas: 0,
        gasPrice: 0,
        gasToken: ethers.ZeroAddress,
        refundReceiver: ethers.ZeroAddress,
      };
  
      // Step 2: Generate the transaction hash
      const txHash = await generateTransactionHash(functionData);
      console.log("txHash: " + txHash);

      // Step 3: Collect signatures
      const signatures = await collectSignatures(txHash);
  
      // Step 4: Execute the transaction using the correct signer
      const txResponse = await contract.execTransaction(
        transaction.to,
        transaction.value,
        transaction.data,
        transaction.operation,
        transaction.safeTxGas,
        transaction.baseGas,
        transaction.gasPrice,
        transaction.gasToken,
        transaction.refundReceiver,
        signatures,
      );
  
      // Wait for the transaction to be mined
      await txResponse.wait();
      console.log(`Transaction executed, new owner added!`);
      setOwners(await contract.getOwners());
    } catch (error) {
      console.error(`Error executing transaction: ${error.message}`);
    }
  };
  
  const generateTransactionHash = async (functionData) => {
    // Replace the following with your actual values
    const to = contractAddress; // Address to call (Gnosis Safe)
    const value = 0; // Value in wei to send
    const data = functionData; // Data payload for the transaction
    const operation = 0; // CALL operation
    const safeTxGas = 0; // Set this appropriately
    const baseGas = 0; // Set this appropriately
    const gasPrice = 0; // Set this appropriately
    const gasToken = ethers.ZeroAddress; // Address zero for ETH
    const refundReceiver = ethers.ZeroAddress; // Address zero if no refund is needed
    const nonce = await contract.nonce(); // Current nonce of the safe
    // Get the transaction hash to sign
    const txHash = await contract.getTransactionHash(
        to,
        value,
        data,
        operation,
        safeTxGas,
        baseGas,
        gasPrice,
        gasToken,
        refundReceiver,
        nonce
    );
    return txHash;
  };

  // Collect Singatures from Owners
  const collectSignatures = async (txHash) => {
    let signatures = "0x";
    let collectSignatures = 0;
    let i = 0;
    let sortedOwners = [...owners].sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    while(collectSignatures < threshold) {
      const signer = await providerRef.current.getSigner(sortedOwners[i]);
      i++;
      const signature = await signer.signMessage(ethers.toBeArray(txHash));
      if(!signature){
        console.log(`Owner ${sortedOwners[i-1]} rejected signature!`)
        continue;
      }
      let sigBytes = ethers.getBytes(signature);
      sigBytes[sigBytes.length - 1] += 4;
      signatures += ethers.hexlify(sigBytes).slice(2);
      console.log(`owner ${sortedOwners[i-1]} signature: ${ethers.hexlify(sigBytes)}`);
      collectSignatures++;
    }
    console.log(`collected ${collectSignatures} signatures with: ${signatures}`);

    return signatures;
  };

  // UI for switching accounts in MetaMask
  const SwitchAccountButton = () => (
    <button onClick={switchAccount} className="switch-account-button">Switch Account</button>
  );

  const updateProfile = async (nickName, dateOfBirth, country) => {
    if (!contract) return;
    await contract.setProfile(1, nickName, dateOfBirth, country);
    alert('Profile updated!');
  };

  return (
    <div className="contract-interaction-page">
      <div className="contract-card-container">
        <div className="contract-card">
          {loading ? (
            <div className="processing-icon">Loading...</div>
          ) : (
            <>
              <h2>Interact with Contract</h2>
              <p>Contract Address: {contractAddress}</p>

              <div className="profile-section">
                <h3>User Profile</h3>
                <p><strong>Username:</strong> {profile.userName}</p>
                <p><strong>Total Acces:</strong> {totalAcces} Acces</p>
                <p><strong>Total Blacxes:</strong> {totalBlacxes} Blacxes</p>
                <p><strong>Gender:</strong> {gender}</p>
                <p><strong>Contract Acces Balance:</strong> {accesBalance} Acces</p>
                <p><strong>Contract Blacxes Balance:</strong> {blacxesBalance} Blacxes</p>
                <p><strong>Oli NFT Balance:</strong> {oliBalance}</p>
              </div>

               {/* Form to update profile */}
               <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const { nickName, dateOfBirth, country } = e.target.elements;
                  updateProfile(nickName.value, dateOfBirth.value, country.value);
                }}
              >
                <input
                  type="text"
                  name="nickName"
                  placeholder="Nickname"
                  className="input-field"
                />
                <input
                  type="text"
                  name="dateOfBirth"
                  placeholder="Date of Birth"
                  className="input-field"
                />
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  className="input-field"
                />
                <button type="submit" className="update-button">
                  Update Profile
                </button>
              </form>

              {/* Display current owners and threshold */}
            <div className="owners-section">
              <h3>Gnosis Safe Owners and Threshold</h3>
              <p><strong>Threshold:</strong> {threshold}</p>
              <ul>
                {owners.map((owner, index) => (
                  <li key={index}><strong>Owner {index + 1}:</strong> {owner}</li>
                ))}
              </ul>
            </div>

            {/* Form to add a new owner and update threshold */}
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const { newOwnerAddress, newThreshold } = e.target.elements;
                await handleAddOwner(
                  newOwnerAddress.value, 
                  newThreshold.value
                );
              }}
            >
              <input
                type="text"
                name="newOwnerAddress"
                placeholder="New Owner Address"
                className="input-field"
                required
              />
              <input
                type="number"
                name="newThreshold"
                placeholder="New Threshold"
                className="input-field"
                required
              />
              <button type="submit" className="update-button">
                Add Owner and Update Threshold
              </button>
            </form>
            {/* Button to switch accounts */}
            <SwitchAccountButton />
          </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContractInteraction;
