import React, { useState } from 'react';
import { ethers } from 'ethers';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import ContractInteraction from './ContractInteraction'; // Import your new contract interaction page
import './App.css';

function HomePage() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [userAddress, setUserAddress] = useState('');
  const [username, setUsername] = useState('');
  const [gender, setGender] = useState('1');  // Default to '1' for male
  const [nonce, setNonce] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);  // State for processing icon
  const [referrerAddress] = useState('0xf98755BAf6Fc3BB47C95794De67B84ddF9473C78');

  const forwarderAddress = process.env.REACT_APP_FORWARDER_ADDRESS;
  const userCreatorAddress = process.env.REACT_APP_USER_CREATOR_ADDRESS;

  const navigate = useNavigate(); // Hook for navigation

  const forwarderABI = [
    {
      "inputs": [
        { "internalType": "address", "name": "from", "type": "address" }
      ],
      "name": "getNonce",
      "outputs": [
        { "internalType": "uint256", "name": "", "type": "uint256" }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ];

  const connectWallet = async () => {
    if (window.ethereum) {
      const browserProvider = new ethers.BrowserProvider(window.ethereum);
      await browserProvider.send("eth_requestAccounts", []);
      const browserSigner = await browserProvider.getSigner();
      const userAddress = await browserSigner.getAddress();

      setProvider(browserProvider);
      setSigner(browserSigner);
      setUserAddress(userAddress);

      const forwarderContract = new ethers.Contract(forwarderAddress, forwarderABI, browserSigner);
      let nonce;
      try {
        nonce = await forwarderContract.getNonce(userAddress);
      } catch (error) {
        if (error.code === 'BAD_DATA' && error.value === '0x') {
          nonce = 0;
        } else {
          throw error;
        }
      }
      console.log("Nonce:", nonce);
      setNonce(nonce);
    } else {
      alert("MetaMask is not installed!");
    }
  };

  const signData = async () => {
    if (!provider || !signer) {
      alert("Please connect your wallet first!");
      return;
    }

    setIsProcessing(true);  // Show processing icon

    const genderNumber = parseInt(gender);  // This will correctly parse '1' or '2'

    const userCreatorInterface = new ethers.Interface([
      "function createUserAccountAndActivate(string _userName, uint256 oliGender, address _referrer) external returns (address)"
    ]);

    const encodedFunctionCall = userCreatorInterface.encodeFunctionData("createUserAccountAndActivate", [username, genderNumber, referrerAddress]);

    const request = {
      from: userAddress,
      to: userCreatorAddress,
      value: 0n,
      gas: 5000000n,
      nonce: BigInt(nonce),
      data: encodedFunctionCall,
    };

    const gasPrice = (await provider.getFeeData()).gasPrice;
    const increasedGasPrice = gasPrice * 1n;

    const domain = {
      name: "MinimalForwarder",
      version: "0.0.1",
      chainId: await provider.getNetwork().then(network => network.chainId),
      verifyingContract: forwarderAddress,
    };

    const types = {
      ForwardRequest: [
        { name: "from", type: "address" },
        { name: "to", type: "address" },
        { name: "value", type: "uint256" },
        { name: "gas", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "data", type: "bytes" },
      ],
    };

    try {
      const signature = await signer.signTypedData(domain, types, request);

      const requestWithStrings = {
        ...request,
        value: request.value.toString(),
        gas: request.gas.toString(),
        nonce: request.nonce.toString(),
        signature: signature,
        gasPrice: increasedGasPrice.toString(),
      };

      const response = await fetch('http://localhost:3005/relay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestWithStrings),
      });

      if (response.ok) {
        const responseData = await response.json();
        alert(`User account created! Contract Address: ${responseData.userCreatedContractAddress}`);
        
        // Redirect to the new page to interact with the contract
        navigate(`/contract/${responseData.userCreatedContractAddress}`);
      } else {
        const errorText = await response.text();
        console.log('Server response:', errorText);
        alert('Failed to send the request to the relay server.');
      }
    } catch (error) {
      console.error('Error signing and sending transaction:', error);
    } finally {
      setIsProcessing(false);  // Hide processing icon
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={connectWallet}>
          {userAddress ? `Connected: ${userAddress}` : "Connect Wallet"}
        </button>

        {userAddress && (
          <div>
            <input
              type="text"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <select value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value="1">Male</option>
              <option value="2">Female</option>
            </select>
            <button onClick={signData} disabled={isProcessing}>Sign and Send to Relay</button>

            {isProcessing && <div className="processing-icon">Processing...</div>}
          </div>
        )}
      </header>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contract/:contractAddress" element={<ContractInteraction />} />
      </Routes>
    </Router>
  );
}

export default App;
